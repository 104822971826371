const faqLink = _ => {

  const FAQ_QUESTION_ATTR  = 'klickart-edit-faq-question'
  const FAQ_LINK_ATTR  = "a[klickart-edit-link-type='faqQuestion']"

  let linkToFaqs = document.body.querySelectorAll(FAQ_LINK_ATTR)

  linkToFaqs.forEach((element) => {
    const id = element.href.split('#')[1]

    element.addEventListener('click', function (event) {
      event.preventDefault()
      let accordion = document.body.querySelector(`[${FAQ_QUESTION_ATTR}=${id}]`)
      if (accordion === null) return

      let position = accordion.getBoundingClientRect().top
      window.scrollTo({
        top: position,
        behavior: 'smooth'
      })

      element.offsetParent.click()
      accordion.click()
      event.stopPropagation()

    })
  })

}

export default faqLink
